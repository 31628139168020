import env from '@y2/environments';
import { NextApiRequest } from 'next';
import { buildAxiosConfigWithCookie, getWithSSRSupport } from '../helpers';
import axios from '../http-client';
import authMiddleware from '../middlewares/auth.middleware';

type LoginData = {
  email: string;
  password: string;
};

export type UserDetailsResponse = {
  message: string;
  data: {
    user: UserData;
    access_token: string;
    refresh_token: string;
  };
};

export enum PropertyOwner {
  NOT_SPECIFIED = 0,
  OWNER = 1,
  TENANT = 2,
  // eslint-disable-next-line no-magic-numbers
  OTHER = 3,
}

export type UserData = {
  Birthday: string;
  Email: string;
  EmailVerified: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
  Tiv: number;
  UUID: string;
  UserID: number;
  MailingList: number;
  profileImage: string | null;
  isPropertyOwner: PropertyOwner;
};

const serviceName = 'auth';

export const MISSING_TOKEN_CODE = '100';

/**
 * client for `auth` service
 * @see https://gw.yad2.io/auth/api-docs/#
 */
export const authClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });
  authMiddleware(client, baseUrl);

  return {
    login: (data: LoginData, reqHeaders?: NextApiRequest['headers']) =>
      client.post('/login', data, {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    logout: () => client.post('/user/logout'),
    getUserDetails: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<UserDetailsResponse>('/user/details', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    refreshToken: (reqHeaders?: NextApiRequest['headers']) =>
      client.post<UserDetailsResponse>('/token/refresh', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
  };
};
