import { useEffect } from 'react';
import { pushAnalyticsUpdateEvent } from './uuid-event-helpers';
import { myDetailsClient } from '@y2/api-clients/my-details';
import { log } from '@y2/log';

const client = myDetailsClient();

export const usePushUuidEventOnLoad = () => {
  useEffect(() => {
    const sendUuidEvent = async () => {
      try {
        const res = await client.getUuidToken();
        const isRecognized = res.data?.data?.recognized;
        const uuid = res.data?.data?.UUID;

        if (isRecognized && uuid) {
          pushAnalyticsUpdateEvent(uuid);
        }
      } catch (error) {
        log.error(
          error,
          'unable to complete send uuid event, issues with the request',
        );
      }
    };

    sendUuidEvent();
  }, []);
};
