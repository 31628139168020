import styled, { css } from 'styled-components';
import Header from './nav-header';

type Props = {
  isHomePage?: boolean;
};

const homePagePart = css`
  box-shadow: none !important;
  background-color: #e3f5f6 !important;
`;

export default styled(Header)<Props>`
  ${({ theme }) => theme.breakpoints.desktop} {
    ${({ isHomePage }) => isHomePage && homePagePart}

    .headlineBox {
      ${({ theme }) => theme.typography.fontSize.l}
      line-height: 70px;
      padding: 0 15px;
      font-weight: 500;
    }
  }
`;
