import { useEffect } from 'react';
import { useCookies, cookie } from '@y2/packages-utils/cookies';

const userSessionPrefix = '_hjSessionUser';
export const y2ValidCookieKey = `${userSessionPrefix}_266550`;

export const useHotjarCookieHandler = () => {
  const [cookies] = useCookies();

  useEffect(() => {
    const invalidCookieKeys = Object.keys(cookies).filter(
      (cookieName) =>
        cookieName.includes(userSessionPrefix) &&
        cookieName !== y2ValidCookieKey,
    );

    invalidCookieKeys.forEach((key) => {
      cookie.remove(key, { path: '/' });
    });
  }, []);
};
