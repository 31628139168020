var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"price-list-b6c770716de44f4f241ebe626f9f7e0d5b7f6706"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/price-list";

import { environment } from './environments/environment';
import { initSentry, OWNERS } from '../../sentry.config.base';

initSentry(environment.env, 'price-list', OWNERS.AMERICA);
