import styled from 'styled-components';
import { CATEGORIES_LIST, Footer } from '@y2/footer';
import { environment } from '../../../environments/environment';

type Props = {
  aboutText?: string;
};

export function MyFooter({ aboutText }: Props) {
  const [, carSubCategories] = CATEGORIES_LIST;

  return (
    <FooterBox
      env={environment.env}
      {...{ aboutText }}
      categoriesList={[carSubCategories]}
    />
  );
}

export default MyFooter;

export const FooterBox = styled(Footer)`
  ${({ theme }) => theme.breakpoints.desktop} {
    nav {
      ul {
        column-count: 6;
        grid-column-gap: 90px;
      }
    }
  }
`;
