/* eslint-disable @typescript-eslint/ban-ts-comment */
import defaultTheme from '../themes/default';
import NavHeader from '../components/layouts/nav-header/nav-header.styled';
import GlobalStyle from '../components/global-style/global-style';
import { Dispatch, SetStateAction, useState } from 'react';
import Footer from '../components/layouts/footer/footer';
import { ThemeProvider } from 'styled-components';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import '@y2/mango/styles';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Head from 'next/head';
import Texts from './index.text.json';
import { useAsyncEffect } from 'ahooks';
import { GoogleTagManagerScript } from '@y2/google-tag-manager';
import { isWebView } from '@y2/react-native-utils';
import { User } from '../services/auth/from-api-data';
import { environment } from '../environments/environment';
import { getCurrentUser } from '../services/auth/get-current-user';
import { ErrorBoundary } from 'react-error-boundary';
import { BoundaryFallback } from './_error';
import { CohortSetup } from '@y2/cohort';
import { BrowserSupport } from '@y2/browser-support';
import { BrazeProvider, BrazeSetup } from '@y2/braze';
import { usePushUuidEventOnLoad } from '@y2/google-tag-manager/update-uuid-event/use-push-uuid-on-load';
import '@y2/mango/fonts';
import env from '@y2/environments';
export type PagePropsExpansion = {
  setFooterText: Dispatch<SetStateAction<string>>;
  user: null | User | undefined;
  isLoading: boolean;
};

function CustomApp({ Component, pageProps }: AppProps<PagePropsExpansion>) {
  const [footerText, setFooterText] = useState(Texts.footerText);
  const [isNativeApp, setIsNativeApp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authUser, setAuthUser] =
    useState<PagePropsExpansion['user']>(undefined);
  const router = useRouter();

  useAsyncEffect(async () => {
    setIsNativeApp(isWebView());

    try {
      setIsLoading(true);
      const user = await getCurrentUser();
      setIsLoading(false);
      setAuthUser(user);
      // eslint-disable-next-line custom-rules/enforce-catch-err-handling
    } catch (error) {
      setIsLoading(false);
      setAuthUser(null);
    }
  }, []);

  usePushUuidEventOnLoad();

  return (
    <>
      <Head>
        <title>Welcome to price-list!</title>
        <meta name="cHash" content={env.commit} />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="yad2" />
        <meta
          property="og:image"
          key="og:image"
          content={`${environment.routes.assets}/yad2site/y2assets/images/logos/head/yad2_logo_205x205.png`}
        />
      </Head>
      {/* @ts-ignore */}
      <ThemeProvider theme={defaultTheme}>
        {/* @ts-ignore */}
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={BoundaryFallback}>
          <BrazeProvider>
            <div className="app">
              {!isNativeApp && <NavHeader isHomePage={router.asPath === '/'} />}
              <main>
                <Component
                  {...pageProps}
                  setFooterText={setFooterText}
                  user={authUser}
                  isLoading={isLoading}
                />
              </main>
              <Footer aboutText={footerText} />
            </div>
            <BrazeSetup uuidFromFetch={authUser?.uuid} isFetching={isLoading} />
          </BrazeProvider>
        </ErrorBoundary>
      </ThemeProvider>
      <CohortSetup />
      <GoogleTagManagerScript environment={environment.env} />
      <BrowserSupport />
    </>
  );
}

export default CustomApp;
