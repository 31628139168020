import {
  wasPageEventTriggered,
  PAGE_EVENT_TRIGGER,
  analytics,
} from '@y2/google-tag-manager';
import { useEffect, useState } from 'react';
import { getAnalyticsPostBrazeEvent } from '../helpers/utils';
import { ANONYMOUS } from '../helpers/constants';

export const usePostPageEvent = (hasSession: boolean, uuid: string | null) => {
  const [hasPageEvent, setHasPageEvent] = useState<boolean>(false);

  useEffect(() => {
    const setPageEventFlag = () => setHasPageEvent(true);

    if (wasPageEventTriggered()) {
      setPageEventFlag();
    } else {
      window.addEventListener(PAGE_EVENT_TRIGGER, setPageEventFlag, {
        once: true,
      });
    }

    return () => {
      window.removeEventListener(PAGE_EVENT_TRIGGER, setPageEventFlag);
    };
  }, [hasPageEvent]);

  useEffect(() => {
    if (hasSession && hasPageEvent) {
      const isUserLogged = Boolean(uuid && uuid !== ANONYMOUS);
      analytics.pushEvent(getAnalyticsPostBrazeEvent(isUserLogged));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession, hasPageEvent]);
};
