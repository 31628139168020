import texts from './nav-header.text.json';

import React from 'react';
import { ResponsiveHeaderGrid, Logo } from '@y2/ui/header';

type Props = {
  className?: string;
};

export default function Header({ className }: Props) {
  return (
    <ResponsiveHeaderGrid
      className={className}
      theme="white"
      logoSlot={<Logo imageToUse="yad2" linkAddress="/" />}
      mobileLogoSlot={<Logo imageToUse="yad2" linkAddress="/" isSmall />}
      hideMobileMenu
      mainMenuSlot={
        <div className="headlineBox">
          <h2>{texts.headerHeadline}</h2>
        </div>
      }
      userDropSlot={<></>}
    />
  );
}
