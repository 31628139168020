/* eslint-disable no-inline-comments */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable id-length */

import 'styled-components';

// This theme file will fill up as long as the project develop.
const theme = {
  breakpoints: {
    mobile: `@media (max-width: 880px)`,
    desktop: `@media (min-width: 881px)`,
  },
  palette: {
    common: {
      white: '#ffffff',
      snow: '#F9F9F9',
      isabelline: '#EEEEEE',
      pastelGrey: '#CCCCCC',
      spanishGrey: '#999999',
      graniteGrey: '#828282',
      snowBank: '#E9E9E9',
      jet: '#363636',
      black: '#000000',
      whiteSmoke: '#f8f8f8',
    },
    primary: {
      linen: '#FFF1E5',
      peachPuff: '#FFDFC4',
      safetyOrange: '#FF7100',
      spanishOrange: '#D86000',
    },
    green: {
      azureishWhite: '#DFF7ED',
      caribbeanGreen: '#0FCA80',
      greenCyan: '#0E9C64',
    },
    red: {
      mistyRose: '#FFE8E8',
      rouge: '#FF2A2B',
      lave: '#CF1A1B',
      redCrayola: '#E2414D',
    },
    blue: {
      dark: '#30313F',
      ghostWhite: '#F6F9FF',
      glitter: '#E0ECFF',
      bleuDeFrance: '#3578E5',
      airForceBlue: '#0A3D8F',
    },
    purple: {
      paleLavender: '#E5D1F8',
      darkOrchid: '#8B42D0',
      violet: '#5800AA',
    },
    lightOrange: {
      cosmicLatte: '#FFF8E5',
      chromeYellow: '#FFAB00',
    },
    yellow: {
      cornsilk: '#fdf9c5',
      bisque: '#eeeac5',
    },
    pink: {
      palepink: '#ffe8e8',
      darkpink: '#f2d3d5',
    },
  },
  shadows: {
    level: [
      `box-shadow: 0 3px 10px 0 rgba(0,0,0,0.05);`,
      `box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);`,
      `box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);`,
      `box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);`,
    ],
  },
  typography: {
    fontSize: {
      // 10px
      xxxs: `font-size: 0.625rem;`,
      // 12px
      xxs: `font-size: 0.75rem;`,
      // 14px
      xs: `font-size: 0.875rem;`,
      // 16px
      s: `font-size: 1rem;`,
      // 18px
      m: `font-size: 1.125rem;`,
      // 20px
      l: `font-size: 1.25rem;`,
      // 22px
      xl: `font-size: 1.375rem;`,
      // 24px
      xxl: `font-size: 1.5rem;`,
      // 28px
      xxxl: `font-size: 1.75rem;`,
      // 36px
      xxxxxl: `font-size: 2.25rem;`,
    },
    fontWeight: {
      light: `font-weight: 300;`,
      regular: `font-weight: 400;`,
      medium: `font-weight: 500;`,
      bolder: `font-weight: 600;`,
    },
    fontFamily: 'font-family: Rubik, sans-serif;',
  },
  zIndex: {
    floaters: `var(--floaters-index)`,
    header: `var(--header-index)`,
    popups: `var(--popups-index)`,
  },
} as const;

type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

export default theme;
