import { useBrazeSetup } from './use-braze-setup';
import { useBrazeContext } from '../braze-provider/braze-provider';
import { usePostPageEvent } from './use-post-page-event';
import { useUserDetails } from '@y2/auth-common/user-details-query';
import { useBrazeUserUuidFetch } from '../hooks/use-braze-user-uuid-fetch';

type Props = {
  customAppKey?: string;
  customBaseUrl?: string;
};

export const BrazeSetup = ({
  customAppKey,
  customBaseUrl,
  uuidFromFetch,
  isFetching,
}: Props & {
  uuidFromFetch?: string;
  isFetching: boolean;
}) => {
  const brazeContext = useBrazeContext();
  const { hasSession, uuid } = useBrazeSetup(
    brazeContext,
    isFetching,
    uuidFromFetch,
    customAppKey,
    customBaseUrl,
  );
  usePostPageEvent(hasSession, uuid);

  return null;
};

export const BrazeSetupReactQuery = ({
  customAppKey,
  customBaseUrl,
}: Props) => {
  const { data: user, isFetching } = useUserDetails();
  const uuid = user?.UUID;

  return (
    <BrazeSetup
      customAppKey={customAppKey}
      customBaseUrl={customBaseUrl}
      uuidFromFetch={uuid}
      isFetching={isFetching}
    />
  );
};

export const BrazeSetupNoStore = ({ customAppKey, customBaseUrl }: Props) => {
  const { isLoading, uuid: fetchedUuid } = useBrazeUserUuidFetch();

  return (
    <BrazeSetup
      customAppKey={customAppKey}
      customBaseUrl={customBaseUrl}
      uuidFromFetch={fetchedUuid}
      isFetching={isLoading}
    />
  );
};
