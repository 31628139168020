import axios, { ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport, buildAxiosConfigWithCookie } from '../helpers';
import authMiddleware from '../middlewares/auth.middleware';
import { NextApiRequest } from 'next';

type TradingPackageData = {
  StartDate: string;
  EndDate: string;
};

export type Profile = {
  id: number;
  firstName: string;
  lastName: string;
  birthday?: string;
  email?: string;
  broker?: Broker;
  emailVerified?: string;
  hasBusinessForSaleAds?: boolean;
  hasCommercialRealEstateAds?: boolean;
  homeAddress?: string;
  homeCity?: string;
  homeNumber?: string;
  isCarAccessoryTrader?: boolean;
  isCarTrader?: boolean;
  isRealEstateMarketing?: boolean;
  isTourismTrader?: boolean;
  isTwoWheeledTrader?: boolean;
  mailingList?: string;
  phone?: string;
  mobile?: string;
  profileImage?: string;
  tiv?: boolean;
  uploadedProfileImage?: string;
  virtualNumbers?: GetMyProfileVirtualNumbersItem[] | null;
  yad2Trade?: boolean;
  isEnterpriseCarTrader?: boolean;
  tradingPackages: {
    dealcartrade: TradingPackageData;
    dealmototrade: TradingPackageData;
  };
};

export type GetMyProfileVirtualNumbersItem = {
  CatID?: number;
  ID?: number;
  oPhone1?: string;
  Phone1?: string;
  SubCatID?: number;
};

interface Broker {
  brokerId: number;
  custId: number;
  primaryBrokerId: number;
}

export interface UpdateProfileData {
  firstName?: string;
  lastName?: string;
  email?: string;
  homeCity?: string;
  homeAddress?: string;
  homeNumber?: string;
  profileImage?: string;
  phone?: string;
  mobile?: string;
  birthday?: string | null;
  mailingList?: string;
}

interface UploadImageResponse {
  image: string;
}

export type GuestResponse = {
  UUID: string;
  recognized: boolean;
};

const serviceName = 'my-details';

/**
 * client for `my-details` service
 * @see https://gw.yad2.io/my-details/api-docs/#
 */
export const myDetailsClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });
  authMiddleware(client, baseUrl);

  return {
    // in SSR phase in serverSideProps pass the headers from the "req" object
    getProfile: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<Profile>('/profile', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    updateProfile: (
      profileData: UpdateProfileData,
      reqHeaders?: NextApiRequest['headers'],
    ) =>
      client.put<Profile>('/profile', profileData, {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    uploadProfileImage: (
      image: FormData,
      reqHeaders?: NextApiRequest['headers'],
    ) =>
      client.post<UploadImageResponse>('/profile-image', image, {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    deleteProfileImage: (reqHeaders?: NextApiRequest['headers']) =>
      client.delete('/profile-image', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    getUuidToken: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<ResponseBody<GuestResponse>>('/guest', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
  };
};
