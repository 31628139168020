/**
 * Lazy-load to avoid window manipulation on SSR and reduce bundle size
 */
import { useEffect, useState } from 'react';

export type BrazeModule = typeof import('@braze/web-sdk');

declare global {
  interface Window {
    braze: BrazeModule;
  }
}

const discoverForGtmTags = (braze: BrazeModule) => {
  window.braze = braze;
};

export const useLazyLoadBraze = (disabled = false) => {
  const [braze, setBraze] = useState<BrazeModule | null>(null);

  useEffect(() => {
    if (disabled) {
      return;
    }

    const loadBraze = async () => {
      const module = await import('@braze/web-sdk');
      discoverForGtmTags(module);
      setBraze(module);
    };

    loadBraze();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return braze;
};
