/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react';
import { defaultAppKey, baseUrls, ANONYMOUS } from '../helpers/constants';
import { useBrazeContext } from '../braze-provider/braze-provider';
import { isPossibleBot } from '@y2/google-tag-manager/is-bot';
import { analytics } from '@y2/google-tag-manager';
import { getDeviceId, getNoBrazeSessionGTMEvent } from '../helpers/utils';
import env from '@y2/environments';

type BrazeContext = ReturnType<typeof useBrazeContext>;

export const useBrazeSetup = (
  brazeContext: BrazeContext,
  isFetchingUuid: boolean,
  uuidFromFetch?: string,
  customAppKey?: string,
  customBaseUrl?: string,
) => {
  const [uuid, setUuid] = useState<string | null>(null);
  const [hasSession, setHasSession] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { braze, setIsActive } = brazeContext;
  const appKey = customAppKey || defaultAppKey;
  const baseUrl = customBaseUrl || baseUrls.seeker;

  // Setup UUID and Bot status
  useEffect(() => {
    const detectedBot = isPossibleBot();
    // Check for false detection of bot
    if (uuidFromFetch && detectedBot) {
      setIsBot(false);
    } else {
      setIsBot(detectedBot);
    }

    if (detectedBot && !uuidFromFetch && !isFetchingUuid) {
      analytics.pushEvent(getNoBrazeSessionGTMEvent());
    }

    setUuid(uuidFromFetch || ANONYMOUS);
  }, [isFetchingUuid, uuidFromFetch]);

  // Initialize Braze
  useEffect(() => {
    if (!braze || isInitialized || isBot) return;

    const enableLogging = env.name === 'staging';
    const deviceId = getDeviceId();
    braze.initialize(appKey, {
      baseUrl,
      enableLogging: true,
      deviceId,
      allowUserSuppliedJavascript: true,
    });
    braze.automaticallyShowInAppMessages();

    if (enableLogging) {
      // eslint-disable-next-line no-console
      braze.setLogger((message: string) => console.log('Braze:', message));
    }

    setIsInitialized(true);
  }, [braze, appKey, baseUrl, isInitialized, isBot]);

  // Open session and set user
  useEffect(() => {
    if (!braze || !isInitialized) return;

    if (uuid && uuid !== ANONYMOUS) {
      braze.changeUser(uuid);
    }

    braze.openSession();
    setHasSession(true);
    setIsActive(true);
  }, [braze, isInitialized, uuid, setIsActive]);

  return {
    hasSession,
    uuid,
  };
};
