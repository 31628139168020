import env from '@y2/environments';

export const baseUrls = {
  seeker: 'https://sdk.iad-07.braze.com',
  smb: 'https://sdk.fra-02.braze.eu',
};

export const ANONYMOUS = 'anonymous-unlogged-user';

const prodKey = '716d3f2d-2039-4ea6-bd67-0782ecd0770b';

const stagingKey = '592e0474-2bed-497a-abe5-6edd3233e155';

export const dataLayerEventKey = 'postBrazeSdkLoad';

export const isProd = env.name === 'prod';

export const defaultAppKey = isProd ? prodKey : stagingKey;

// shared with auth: apps/auth/src/app/services/session/user-actions-status.ts
export const statusFromAuthKey = 'statusFromAuthPage';
export const registeredAuthStatus = 'registered';
export const loggedAuthStatus = 'logged';
