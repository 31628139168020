import {
  useLazyLoadBraze,
  type BrazeModule,
} from '../hooks/use-lazyload-braze';
import { useState, createContext, useContext } from 'react';

type Props = {
  children: React.ReactNode;
};

type BrazeContextType = {
  braze: BrazeModule | null;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
};

const BrazeContext = createContext<BrazeContextType | undefined>(undefined);

export const BrazeProvider = ({ children }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const braze = useLazyLoadBraze();

  return (
    <BrazeContext.Provider value={{ braze, isActive, setIsActive }}>
      {children}
    </BrazeContext.Provider>
  );
};

export const useBrazeContext = () => {
  const context = useContext(BrazeContext);
  if (!context) {
    throw new Error('useBraze must be used within a BrazeProvider');
  }

  return context;
};
