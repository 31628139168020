/* eslint-disable no-underscore-dangle */
import {
  statusFromAuthKey,
  registeredAuthStatus,
  loggedAuthStatus,
  dataLayerEventKey,
} from './constants';

// get brazeDeviceId from session storage
export const getDeviceId = () => {
  const deviceId = sessionStorage.getItem('brazeDeviceID');

  return deviceId || undefined;
};

export const getStatusFromAuthApp = (isUserLoggedIn: boolean) => {
  const sessionStatus = sessionStorage.getItem(statusFromAuthKey);
  sessionStorage.removeItem(statusFromAuthKey);

  if (!isUserLoggedIn) {
    return 'disconnect';
  }
  if (sessionStatus === registeredAuthStatus) {
    return 'register';
  }
  if (sessionStatus === loggedAuthStatus) {
    return 'logged';
  }

  return 'refresh';
};

export const getAnalyticsPostBrazeEvent = (isUserLoggedIn: boolean) => {
  const userStatus = getStatusFromAuthApp(isUserLoggedIn);

  return {
    event: dataLayerEventKey,
    userStatus,
  };
};

export const getNoBrazeSessionGTMEvent = () => ({
  event: 'disableBrazeOnSuspectedBot',
});
