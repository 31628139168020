import React from 'react';
import Script from 'next/script';
import { envState } from './helpers';
import { AdBlockStatusScript } from './ad-block-status-script';
import { useHotjarCookieHandler } from './hotjar/use-hotjar-cookie-handler';
import {
  DEFAULT_GA_TRACKING_ID,
  getGtmInlineScriptSnippet,
} from './gtm-inline-script-snippet';

type Props = {
  environment: string;
  customId?: string;
};

export const GoogleTagManagerScript: React.VFC<Props> = ({
  environment,
  customId,
}) => {
  const id = customId || DEFAULT_GA_TRACKING_ID;
  envState.setEnvironment(environment);

  useHotjarCookieHandler();

  return (
    <>
      <AdBlockStatusScript />
      <Script
        id="gtm-dataLayer-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: getGtmInlineScriptSnippet(id),
        }}
      />
    </>
  );
};
