/* istanbul ignore file */

import type { UserDetailsResponse } from '@y2/api-clients/auth';

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  uuid: string;
};

/**
 * reshapes data from API
 */
export function fromApiData(response: UserDetailsResponse): User {
  // prettier-ignore
  const {
    FirstName: firstName,
    LastName: lastName,
    UserID: id,
    UUID: uuid,
  } = response.data.user;

  return {
    id,
    firstName,
    lastName: lastName ?? '',
    uuid,
  };
}
