import type { NextApiRequest } from 'next';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

export type { NextApiRequest };

const getHeaders = (headers?: NextApiRequest['headers']) => {
  if (!headers) {
    return {};
  }

  return {
    headers: {
      ...headers,
      ...(headers.cookie ? { Cookie: headers.cookie } : {}),
    },
  };
};

export const buildAxiosConfigWithCookie = (
  headers?: NextApiRequest['headers'],
) => ({
  withCredentials: true,
  ...getHeaders(headers),
});

export const overrideHeaders = (headers: Record<string, string | undefined>) =>
  omitBy(headers, isUndefined);
