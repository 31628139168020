import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: ${({ theme }) => theme.palette.common.white};
    direction: rtl;
    color: rgb(var(--jet));
    min-height: 100%;
  }
`;

export default GlobalStyle;
