export const environment = {
  production: true,
  env: 'production',
  routes: {
    apiBaseUrl: 'https://gw.yad2.co.il',
    base: '/price-list',
    domain: '.yad2.co.il',
    assets: 'https://assets.yad2.co.il',
    mainSite: 'https://www.yad2.co.il',
  },
};
