import type { AxiosInstance } from 'axios';
import axios from '../http-client';
import { log } from '@y2/log';

const WITH_CREDENTIALS_REQUEST = 'gw.yad2';
const UNAUTHORIZED_STATUS_CODE = 401;
const OK_STATUS_CODE = 200;
const isBrowser = typeof window !== 'undefined';

let isRefreshing = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let failedQueue: any[] = [];

const handleUnauthenticatedRequest = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  apiEndpoint: string,
) => {
  const originalRequest = error.config;
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    })
      .then(() => {
        originalRequest.withCredentials = true;
        return axios.request(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  isRefreshing = true;

  const { status } = await axios.post(
    `${apiEndpoint}/auth/token/refresh`,
    {},
    { withCredentials: true },
  );

  if (status === OK_STATUS_CODE) {
    try {
      const response = await axios.request(originalRequest);
      processQueue();
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      log.info(err);
      processQueue(error);
      return Promise.reject(error);
    } finally {
      isRefreshing = false;
    }
  }
  return Promise.reject(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processQueue = (error?: any) => {
  if (error) {
    failedQueue.forEach((prom) => {
      prom.reject(error);
    });
  } else {
    failedQueue.forEach((prom) => {
      prom.resolve();
    });
  }
  failedQueue = [];
};

export default function (client: AxiosInstance, apiEndpoint: string) {
  client.interceptors.request.use((config) => {
    if (config?.url?.includes(WITH_CREDENTIALS_REQUEST)) {
      // eslint-disable-next-line no-param-reassign
      config.withCredentials = true;
    }
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    // eslint-disable-next-line require-await
    async (error) => {
      if (isBrowser && error?.response?.status === UNAUTHORIZED_STATUS_CODE) {
        return handleUnauthenticatedRequest(error, apiEndpoint);
      }
      return Promise.reject(error);
    },
  );
}
